import React, { createContext } from "react";
import AuthStore from "./auth";
import AppState from "./AppState";
import CollectionState from "./CollectionState";
import ContestState from "./ContestState";
import LeagueGamesState from "./LeagueGamesState";
import MarketState from "./MarketState";
import MintingState from "./MintingState";
import PageState from "./PageState";
import WalletState from "./WalletState";
import NotificationState from "./Notification";

export const StoreContext = createContext<any>(null);

export function ContextWrapper({ children }) {
  const App = AppState();
  const Auth = AuthStore();
  const Collection = CollectionState();
  const Contest = ContestState();
  const Minting = MintingState();
  const Wallet = WalletState();
  const Page = PageState();
  const Market = MarketState();
  const LeagueGames = LeagueGamesState();
  const Notification = NotificationState();

  const data = {
    app: App.data || {},
    auth: Auth.data,
    collection: Collection.data || {},
    contest: Contest.data || {},
    minting: Minting.data || {},
    wallet: Wallet.data || {},
    page: Page.data || "",
    market: Market.data || {},
    leagueGames: LeagueGames.data,
    notifications: Notification.data,
  };

  const contracts = {
    minting: Minting.contract || {},
  };

  const actions = {
    ...App.actions,
    auth: Auth.actions,
    leagueGames: LeagueGames.actions,
    ...Collection.actions,
    ...Contest.actions,
    ...Minting.actions,
    ...Wallet.actions,
    ...Page.actions,
    ...Market.actions,
    ...Notification.actions,
  };

  return (
    <StoreContext.Provider value={{ data, actions, contracts }}>
      {children}
    </StoreContext.Provider>
  );
}
