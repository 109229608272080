import { useState } from "react";

const NotificationState = () => {
  const [data, setData] = useState([]);

  const actions = {
    setNotifications: (data) => setData(data),
  };

  return {
    data,
    actions,
  };
};

export default NotificationState;
