import React from "react";
import { useState } from "react";

const PageState = () => {
  const [data, setData] = useState("");

  const actions = {
    setFromRoute: (data: string) => setData(data),
  };
  return { data, actions };
};

export default PageState;
