import { useState } from "react";

const initialState = {
    walletConnected: false,
    connectedWallet: {},
    web3Provider: {},
    balance: null,
};

const WalletState = () => {
    const [data, setData] = useState(initialState);
    const [web3Modal, setWeb3Modal] = useState(null);

    const actions = {
        setWallet: (data) => setData((w) => ({ ...w, ...data })),
        setWeb3Modal: (data) => setWeb3Modal(data),
        setWalletBalance: (data) =>
            setData((state) => ({ ...state, balance: data })),
    };

    return {
        data: {
            ...data,
            web3Modal,
        },
        actions,
    };
};

export default WalletState;
