export const CS_1155_NFT_ADDRESS = "0xC6d4e6cB0F425D586A2884670dE8903eB3D6bA12";
export const CS_1155_MINT_ADDRESS =
  "0x4c23650A716798a0902E548F65c06862193BceEB";
export const CS_1155_NFL_ADDRESS = "0x14eB8CEd8C1B3aFFB5DE98aAe071f2F6B21250E7";
export const CS_1155_NFL_MINT_ADDRESS =
  "0x1Fd9c13D4308f5Ae98094D68B371A4ed46FD0D6d";

export const CS_1155_NFT_ABI =
  require("../components/abi/CryptoSportsNFT.json").abi;
export const CS_1155_MINT_ABI =
  require("../components/abi/CryptoSportsMintingMgmt.json").abi;

export const CS_1155_NFT_NFL_ABI =
  require("../components/abi/CryptoSportsNFT_NFL.json").abi;
export const CS_1155_MINT_NFL_ABI =
  require("../components/abi/CryptoSportsMintingMgmt_NFL.json").abi;

export const BLOCKEXPLORER = "https://mumbai.polygonscan.com/";

export const TOAST_SETTINGS = {
  style: {
    borderRadius: "10px",
    background: "#333",
    color: "#fff",
  },
};

export const OPENSEA_URL =
  "https://testnets.opensea.io/assets/mumbai/0xC6d4e6cB0F425D586A2884670dE8903eB3D6bA12/";

export const BLOCK_EXPLORER_URL =
  "https://mumbai.polygonscan.com/address/0xC6d4e6cB0F425D586A2884670dE8903eB3D6bA12";

export const COLLECTION_URL =
  "https://mumbai.polygonscan.com/address/0xC6d4e6cB0F425D586A2884670dE8903eB3D6bA12";

export const DATE_FORMAT = "mm/dd/yyyy";

export const positions = [
  // "Point Guard",
  // "Shooting Guard",
  // "Center",
  // "Power Forward",
  // "Small Forward",
  "Any Player",
  "Any Player",
  "Any Player",
  "Any Player",
  "Any Player",
];

export const MAX_AGE = 200;
export const MIN_AGE = 18;

export const TEAMS = [
  {
    id: 28,
    city: "Los Angeles",
    name: "Clippers",
    label: "Los Angeles Clippers",
    value: "LAC",
  },
  {
    id: 23,
    city: "New Orleans",
    name: "Pelicans",
    label: "New Orleans Pelicans",
    value: "NO",
  },
  {
    id: 5,
    city: "Orlando",
    name: "Magic",
    label: "Orlando Magic",
    value: "ORL",
  },
  {
    id: 3,
    city: "Atlanta",
    name: "Hawks",
    label: "Atlanta Hawks",
    value: "ATL",
  },
  {
    id: 13,
    city: "Indiana",
    name: "Pacers",
    label: "Indiana Pacers",
    value: "IND",
  },
  {
    id: 11,
    city: "Chicago",
    name: "Bulls",
    label: "Chicago Bulls",
    value: "CHI",
  },
  {
    id: 27,
    city: "Los Angeles",
    name: "Lakers",
    label: "Los Angeles Lakers",
    value: "LAL",
  },
  {
    id: 15,
    city: "Milwaukee",
    name: "Bucks",
    label: "Milwaukee Bucks",
    value: "MIL",
  },
  {
    id: 30,
    city: "Sacramento",
    name: "Kings",
    label: "Sacramento Kings",
    value: "SAC",
  },
  {
    id: 4,
    city: "Miami",
    name: "Heat",
    label: "Miami Heat",
    value: "MIA",
  },
  {
    id: 22,
    city: "Houston",
    name: "Rockets",
    label: "Houston Rockets",
    value: "HOU",
  },
  {
    id: 26,
    city: "Golden State",
    name: "Warriors",
    label: "Golden State Warriors",
    value: "GS",
  },
  {
    id: 1,
    city: "Washington",
    name: "Wizards",
    label: "Washington Wizards",
    value: "WAS",
  },
  {
    id: 6,
    city: "New York",
    name: "Knicks",
    label: "New York Knicks",
    value: "NY",
  },
  {
    id: 7,
    city: "Philadelphia",
    name: "76ers",
    label: "Philadelphia 76ers",
    value: "PHI",
  },
  {
    id: 24,
    city: "San Antonio",
    name: "Spurs",
    label: "San Antonio Spurs",
    value: "SA",
  },
  {
    id: 9,
    city: "Boston",
    name: "Celtics",
    label: "Boston Celtics",
    value: "BOS",
  },
  {
    id: 29,
    city: "Phoenix",
    name: "Suns",
    label: "Phoenix Suns",
    value: "PHO",
  },
  {
    id: 12,
    city: "Cleveland",
    name: "Cavaliers",
    label: "Cleveland Cavaliers",
    value: "CLE",
  },
  {
    id: 17,
    city: "Utah",
    name: "Jazz",
    label: "Utah Jazz",
    value: "UTA",
  },
  {
    id: 8,
    city: "Brooklyn",
    name: "Nets",
    label: "Brooklyn Nets",
    value: "BKN",
  },
  {
    id: 10,
    city: "Toronto",
    name: "Raptors",
    label: "Toronto Raptors",
    value: "TOR",
  },
  {
    id: 14,
    city: "Detroit",
    name: "Pistons",
    label: "Detroit Pistons",
    value: "DET",
  },
  {
    id: 2,
    city: "Charlotte",
    name: "Hornets",
    label: "Charlotte Hornets",
    value: "CHA",
  },
  {
    id: 18,
    city: "Oklahoma City",
    name: "Thunder",
    label: "Oklahoma City Thunder",
    value: "OKC",
  },
  {
    id: 20,
    city: "Denver",
    name: "Nuggets",
    label: "Denver Nuggets",
    value: "DEN",
  },
  {
    id: 21,
    city: "Memphis",
    name: "Grizzlies",
    label: "Memphis Grizzlies",
    value: "MEM",
  },
  {
    id: 16,
    city: "Minnesota",
    name: "Timberwolves",
    label: "Minnesota Timberwolves",
    value: "MIN",
  },
  {
    id: 25,
    city: "Dallas",
    name: "Mavericks",
    label: "Dallas Mavericks",
    value: "DAL",
  },
  {
    id: 19,
    city: "Portland",
    name: "Trail Blazers",
    label: "Portland Trail Blazers",
    value: "POR",
  },
];

export const scoreboardLink = "https://sports.yahoo.com/nba/scoreboard";

export const BOOSTERPACK_COUNT = 8;

export const SIGN_UP_BOOSTERPACK_TYPE = "SIGN_UP_BOOSTERPACK";

export const LEAGUES = [
  {
    icon: "/images/nba.svg",
    label: "NBA",
    id: "nba",
    disabled: true
  },
  {
    icon: "/images/nfl.png",
    label: "NFL",
    id: "nfl",
  },
];

export const ABOUT_SITE_URL =
  "https://cryptosports.notion.site/About-the-Game-60bf70613b0849839276636957e41f48";

export const DISCORD_URL = "https://discord.gg/NpAw6nmzbS";

export const NBA_POSITIONS = [
    {
        value: 'C',
        label: 'C'
    },
    {
        value: 'PG',
        label: 'PG'
    },
    {
        value: 'SG',
        label: 'SG'
    },
    {
        value: 'SF',
        label: 'SF'
    },
    ,
    {
        value: 'PF',
        label: 'PF'
    }
]

export const NFL_POSITIONS = [
    {
        value: 'LB',
        label: 'LB'
    },
    {
        value: 'QB',
        label: 'QB'
    },
    {
        value: 'RB',
        label: 'RB'
    },
    {
        value: 'TE',
        label: 'TE'
    },
    {
        value: 'WR',
        label: 'WR'
    },
    {
        value: '',
        label: 'Flex'
    },
    {
        value: 'DST',
        label: 'D/ST'
    },
]

export const POSITIONS_LIST = {
    nba: NBA_POSITIONS,
    nfl: NFL_POSITIONS
}

export const CURRENCY: 'TOKEN' | 'FIAT' = 'TOKEN'

export const LEADER_PRIZE_LIST = [
  {
    ranks: '1st',
    rewards: '500 credits + {NFT}',
    icon: '/images/icons/trophy-gold.svg'
  },
  {
    ranks: '2nd',
    rewards: '400 credits + {NFT}',
    icon: '/images/icons/trophy-silver.svg'
  },
  {
    ranks: '3rd',
    rewards: '300 credits + {NFT}',
    icon: '/images/icons/trophy-bronze.svg'
  },
  {
    ranks: '4th',
    rewards: '200 credits',
    icon: '/images/icons/trophy-black.svg'
  },
  {
    ranks: '5th',
    rewards: '100 credits',
    icon: '/images/icons/trophy-black.svg'
  },
  {
    ranks: '6th - 10th',
    rewards: '50 credits',
    icon: '/images/icons/trophy-black.svg'
  },
]