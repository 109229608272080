import { useState } from "react";
import {
  ICollection,
  PriceRangeType,
  IMarketState,
  IPlayerMetadata,
  PlayerFiltersType,
  SortByType,
  IGames,
  Game,
} from "@/types";

const initialState = {
  gamesToday: null,
};

const LeagueGamesState = () => {
  const [data, setData] = useState<IGames>(initialState);

  const actions = {
    setGamesToday: (gamesToday: Game[]) =>
      setData((data) => ({ ...data, gamesToday })),
    resetGames: () => setData(() => initialState),
  };

  return {
    data,
    actions,
  };
};

export default LeagueGamesState;
