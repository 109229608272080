import { useState } from "react";
import {
  ICollectionState,
  PlayerFiltersType,
  SortByType,
  PriceRangeType,
} from "@/types";

const initialState = {
  collection: [],
  isLoading: true,
  filters: {},
  sortBy: null,
  page: 1,
};

const CollectionState = () => {
  const [data, setData] = useState<ICollectionState>(initialState);

  const actions = {
    setCollection: (data: ICollectionState) => setData(data),
    setIsLoading: (loading: boolean) =>
      setData((d) => ({ ...d, isLoading: loading })),
    setFiltersCollection: (filters: PlayerFiltersType) =>
      setData((d) => ({ ...d, filters })),
    setSortCollection: (sortBy: SortByType) =>
      setData((d) => ({ ...d, sortBy })),
    setPriceRangeCollection: (priceRange: PriceRangeType) =>
      setData((data) => ({ ...data, priceRange })),
    setRatingCollection: (priceRange: PriceRangeType) =>
      setData((data) => ({ ...data, priceRange })),
    setCollectionPage: (page: number) => setData((data) => ({ ...data, page })),
  };

  return {
    data,
    actions,
  };
};

export default CollectionState;
