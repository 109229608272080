import { useState } from "react";
import {
  ICollection,
  PriceRangeType,
  IMarketState,
  IPlayerMetadata,
  PlayerFiltersType,
  SortByType,
} from "@/types";

const initialState = {
  topPerformers: null,
  marketData: null,
  teamsData: null,
  searchData: [],
  filters: {},
  sortBy: null,
  isLoading: true,
  page: 1,
};

const MarketState = () => {
  const [data, setData] = useState<IMarketState>(initialState);

  const actions = {
    setTopPerformers: (topPerformers: ICollection[]) =>
      setData((data) => ({ ...data, topPerformers })),
    setMarketdata: (marketData: ICollection[]) =>
      setData((data) => ({ ...data, marketData })),
    setMarketTeamsData: (teamsData: ICollection[]) =>
      setData((data) => ({ ...data, teamsData })),
    setSearchData: (searchData: IPlayerMetadata[]) =>
      setData((data) => ({ ...data, searchData })),
    setWalletIsLoading: (isLoading: boolean) =>
      setData((data) => ({ ...data, isLoading })),
    setFiltersMarketplace: (filters: PlayerFiltersType) =>
      setData((data) => ({ ...data, filters })),
    setSortMarketplace: (sortBy: SortByType) =>
      setData((data) => ({ ...data, sortBy })),
    setPriceRange: (priceRange: PriceRangeType) =>
      setData((data) => ({ ...data, priceRange })),
    setMinMaxRating: (rating: PriceRangeType) =>
      setData((data) => ({ ...data, rating })),
    setMarketPage: (page: number) => setData((data) => ({ ...data, page })),
  };

  return {
    data,
    actions,
  };
};

export default MarketState;
